class GridTable {
    constructor($el, el, headers, rows, cols, cells) {
        this.$el = $el;
        this.el = el;
        this.headers = headers;
        this.rows = rows;
        this.cols = cols;
        this.cells = cells;
        this.colMap = {};
        this.getHeaderIndex = (key) => {
            if (typeof key === 'number')
                return key;
            if (this.headerNames.includes(key)) {
                return this.headerNames.indexOf(key);
            }
            else {
                return false;
            }
        };
        this.headerNames = headers.map(item => item.data('name'));
        for (const [idx, name] of indexValuePairs(this.headerNames)) {
            this.colMap[name] = cols[idx];
        }
    }
    ;
    column(key) {
        if (typeof key === "string") {
            if (key in this.colMap) {
                return this.colMap[key];
            }
            else {
                return [];
            }
        }
        else {
            return this.cols[key];
        }
    }
    hideColumn(key) {
        if (Array.isArray(key)) {
            for (const k of key) {
                this.hideColumn(k);
            }
            return this;
        }
        const idx = this.getHeaderIndex(key);
        if (typeof idx === 'number') {
            if (this.headers[idx].is('.hide')) {
                return this;
            }
            else {
                this.headers[idx].addClass('hide');
            }
        }
        const column = this.column(key);
        for (const cell of column) {
            if (!cell.hasData('colspan') || cell.hasData('colspan', '1')) {
                cell.addClass('hide');
            }
        }
        this.$el.css('--cols', this.$el.children('.header:not(.hide)').length);
        column.filter(item => item.withData('colspan')).forEach(cell => {
            const colspan = parseInt(cell.data('colspan')) || 1;
            cell.data('colspan', colspan - 1).css('--colspan', colspan - 1);
        });
        return this;
    }
    showColumn(key) {
        if (Array.isArray(key)) {
            for (const k of key) {
                this.showColumn(k);
            }
            return this;
        }
        const idx = this.getHeaderIndex(key);
        if (typeof idx === 'number') {
            if (this.headers[idx].is('.hide')) {
                this.headers[idx].removeClass('hide');
            }
            else {
                return this;
            }
        }
        const column = this.column(key);
        for (const cell of column) {
            cell.removeClass('hide');
        }
        this.$el.css('--cols', this.$el.children('.header:not(.hide)').length);
        column.filter(item => item.withData('colspan')).forEach(cell => {
            const colspan = parseInt(cell.data('colspan')) || 0;
            cell.data('colspan', colspan + 1).css('--colspan', colspan + 1);
        });
        return this;
    }
    sortBy(key, method = undefined, dir = undefined) {
        const idx = this.getHeaderIndex(key);
        if (idx) {
            const header = this.headers[idx];
            const sortMethodKey = method || header.data('grid-table-sort');
            const sortMethod = gridTablesSortMethods[sortMethodKey];
            if (sortMethod) {
                const sortDir = dir || (function () {
                    switch (header.data('grid-table-sort-dir')) {
                        case 'asc':
                            return 'desc';
                        case 'desc':
                            return 'none';
                        default:
                            return 'asc';
                    }
                })();
                let sortedRows = this.rows.concat([]);
                if (sortDir != 'none') {
                    sortedRows.sort((a, b) => {
                        if (sortDir == 'asc') {
                            return sortMethod(a[idx], b[idx]);
                        }
                        else {
                            return -sortMethod(a[idx], b[idx]);
                        }
                    });
                }
                for (const row of sortedRows) {
                    for (const cell of row) {
                        this.$el.append(cell);
                    }
                }
                header.removeClass(['sorted-asc', 'sorted-desc']);
                if (sortDir != 'none') {
                    header.addClass(`sorted-${sortDir}`);
                }
                header.data('grid-table-sort-dir', sortDir);
                this.headers
                    .filter((_, index) => index != idx)
                    .forEach(item => {
                    item
                        .removeClass(['sorted-asc', 'sorted-desc'])
                        .data('grid-table-sort-dir', 'none');
                });
            }
        }
        return this;
    }
    where(condition) {
        return this.cells.filter(condition);
    }
    slice(startPoint, endPoint) {
        const getTrueIndex = (idx, max) => idx == null ? max : (idx >= 0 ? idx : max + idx);
        const col_max = this.cols.length - 1;
        const row_max = this.rows.length - 1;
        // [...[stuff]].map is a workaround to map empty array elements also
        const [col_start, row_start] = [...(startPoint || [0, 0])].map((coord, i) => getTrueIndex(coord, [col_max, row_max][i]));
        const [col_end, row_end] = [...(endPoint || [col_max, row_max])].map((coord, i) => getTrueIndex(coord, [col_max, row_max][i]));
        return this.where(cell => {
            const row_idx = cell.data('row-idx');
            const col_idx = cell.data('col-idx');
            return (row_idx >= row_start) && (row_idx <= row_end) && (col_idx >= col_start) && (col_idx <= col_end);
        });
    }
}
var gridTables = {};
var gridTablesSortMethods = {
    text: function (cell1, cell2) {
        const text1 = cell1.text().trim();
        const text2 = cell2.text().trim();
        return text1.localeCompare(text2);
    },
    price: function (cell1, cell2) {
        const price1 = parseFloat(cell1.find('.price-container .price').text()) || 0;
        const price2 = parseFloat(cell2.find('.price-container .price').text()) || 0;
        return price1 - price2;
    }
};
function initGridTables(scope = document) {
    $(scope).find('.grid-table').not(initialized).each(function (table_idx) {
        const table = $(this);
        const headers = table.children('.header').toArray().map(item => $(item));
        const col_num = headers.length;
        const cells = table.children('.cell').not('.header').toArray().map(cell => $(cell));
        const rows = [];
        const cols = [...Array(col_num)].map(_ => []);
        let row_idx = 0;
        if (table.attr('id') === undefined) {
            table.attr('id', `grid-table-${table_idx}`);
        }
        for (let n = 0; n < cells.length;) {
            let row = getRow(row_idx);
            for (let col_idx = 0; col_idx < col_num;) {
                if (row[col_idx] !== undefined) {
                    cols[col_idx][row_idx] = row[col_idx];
                    col_idx += 1;
                    continue;
                }
                const colspan = Math.max(1, parseInt(cells[n].data('colspan')) >> 0);
                const rowspan = Math.max(1, parseInt(cells[n].data('rowspan')) >> 0);
                for (let x = col_idx; x < (col_idx + colspan); x++) {
                    row[x] = cells[n];
                    cols[x][row_idx] = cells[n];
                }
                for (let y = 1; y < rowspan; y++) {
                    getRow(row_idx + y)[col_idx] = cells[n];
                }
                cells[n].data('row-idx', row_idx);
                cells[n].data('col-idx', col_idx);
                col_idx += colspan;
                n += 1;
            }
            row_idx += 1;
        }
        headers.filter(item => item.hasData('grid-table-sort')).forEach(item => {
            if (item.data('grid-table-sort') in gridTablesSortMethods) {
                item.on('click', sortColumn);
            }
        });
        gridTables['#' + table.attr('id')] = new GridTable(table, table[0], headers, rows, cols, cells);
        table.data('grid-table-initialized', true);
        function getRow(idx) {
            if (rows[idx] === undefined) {
                rows[idx] = Array(col_num);
            }
            return rows[idx];
        }
    });
    function initialized() {
        return $(this).hasData('grid-table-initialized');
    }
    function sortColumn() {
        const header = $(this);
        const headerName = header.data('name');
        const tableId = header.closest('.grid-table').attr('id');
        const table = gridTables['#' + tableId];
        table.sortBy(headerName);
    }
}
