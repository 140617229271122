function contactComponent() {
    if (exists('.g-recaptcha')) {
        loadCaptcha();
    }
    $('#sortpic').on('change', function (e) {
        const file_data = e.currentTarget.files[0];
        const input = $(this);
        const attachment = $('[name=attachment]');
        if (exists(attachment)) {
            attachment.remove();
        }
        if (file_data.size > 50 * Math.pow(2, 20) /* 50 MB */) {
            showMessageBox('danger', msg['ERR_FILE_TOO_LARGE'].replace(':size', '50 MB'));
            input.val(null);
        }
        else {
            let form_data = new FormData();
            form_data.append('file', file_data);
            form_data.append('type', 'buy2bee');
            $.ajax({
                method: 'POST',
                url: 'https://ftp.dev.zero11.net/storage/buy2bee/up.php',
                dataType: 'text',
                cache: false,
                contentType: false,
                processData: false,
                data: form_data,
                success: function (php_script_response) {
                    const data = JSON.parse(php_script_response);
                    if (data.status == 'OK') {
                        $(`<input type="hidden" name="attachment" value="${data.link}">`).insertAfter('#sortpic');
                    }
                    else {
                        input.val(null);
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                },
                error: function () {
                    input.val(null);
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            });
        }
    });
    monitorInput('#contact-form', 'subject', function (input) {
        return $(input).val() != '';
    }, function () {
        const val = $('#contact-form').find('[name=subject]').val();
        $('#contact-form').find('.contact-form-options').hide();
        $('#' + val).show();
    }, function () {
        $('#contact-form').find('.contact-form-options').hide();
    });
}
function submitContactForm() {
    const form = $('#contact-form')[0];
    // @ts-ignore
    if (form && form.isValid()) {
        // @ts-ignore
        form.submit();
    }
}
