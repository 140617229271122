/**
 * Initializes Photoswipe lightboxes if any are present in the page (elements with the .pswp class).
 */
function initLightbox() {
    if (exists('.pswp')) {
        requireModule('photoswipe', function () {
            $('.pswp').each(function () {
                const pswp = $(this);
                const src = $(pswp.data('src'));
                if (exists(src)) {
                    let items = [];
                    if (src.is('img')) {
                        addGalleryItem(0, src[0]);
                    }
                    else {
                        const imgs = src.find('img').toArray();
                        for (const [index, img] of indexValuePairs(imgs)) {
                            addGalleryItem(index, img);
                        }
                    }
                    function addGalleryItem(index, img) {
                        $(img)
                            .one('load', () => items[index] = {
                            src: img.src,
                            w: img.naturalWidth,
                            h: img.naturalHeight
                        })
                            .on('click', () => initGallery({ index: index, tapToClose: true, }));
                        if (img.complete) {
                            $(img).trigger('load');
                        }
                    }
                    function initGallery(options) {
                        try {
                            const gallery = new PhotoSwipe(pswp[0], PhotoSwipeUI_Default, items, options);
                            gallery.init();
                        }
                        catch (_a) {
                            //@ts-ignore "msg" is guaranteed to exist
                            showMessageBox('danger', msg['ERR_LIGHTBOX_LOADING']);
                        }
                    }
                }
            });
        });
    }
}
