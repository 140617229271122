/**
 * Initializes toggling elements within "scope". Use guide:
 *
 * Terminology:
 *      toggler: element with the data-toggle attribute
 *      toggleable: element(s) matching the selector of data-toggle
 *
 * Toggler attributes:
 *      data-toggle="selector": Indicates that this element will be a toggler and that the toggleable element(s) will be the ones matching "selector"
 *      data-toggle-slide: The toggling animation will be sliding up/down
 *      data-toggle-fade: The toggling animation will be fading in/out
 *      data-toggle-none: There will be no toggling animation, only the .open class will be added/removed (for custom CSS/JS animations)
 *      data-toggle-anim="animation": The toggling animation will be the one registered with registerToggleAnimation(key, animation), "show", "slide", "fade" and "none" exist by default
 *      data-toggle-exclude="selector": The elements matching "selector" will be closed when the toggleable element opens
 *      data-toggle-trigger="event": the dropdown will be shown in response to "event" ("click by default")
 *
 * Toggler classes:
 *      .open: when the toggleable element is open
 *
 * Toggleable classes:
 *      .open: when the element is open
 *
 * Toggleable events:
 *      "toggle:open": opens the toggleable element
 *      "toggle:close": closes the toggleable element
 *
 * @param scope Selector of the element within which activate toggling elements
 */
var __toggleAnimations = {
    none: {
        open: () => { },
        close: () => { }
    },
    show: {
        open: elem => elem.show(),
        close: elem => elem.hide()
    },
    slide: {
        open: elem => elem.slideDown(),
        close: elem => elem.slideUp()
    },
    fade: {
        open: elem => elem.fadeIn(),
        close: elem => elem.fadeOut()
    }
};
function initToggle(scope = document) {
    $(scope).find('*').withData('toggle').not(initialized).each(function () {
        const toggler = $(this);
        const toggleableSelector = toggler.data('toggle');
        const toggleable = $(toggleableSelector);
        const trigger = toggler.data('toggle-trigger') || 'click';
        const animation = toggler.data('toggle-anim') ||
            (toggler.data('toggle-slide') !== undefined ? 'slide' : undefined) ||
            (toggler.data('toggle-fade') !== undefined ? 'fade' : undefined) ||
            (toggler.data('toggle-none') !== undefined ? 'none' : undefined) ||
            'show';
        toggler.data('toggle-initialized', true);
        if (exists(toggleableSelector)) {
            const exclude = toggler.data('toggle-exclude');
            const $exclude = exists(exclude) ? $(exclude) : null;
            toggler.on(trigger, function () {
                if (toggler.is('.open')) {
                    toggler.removeClass('open');
                    toggleable.trigger('toggle:close');
                }
                else {
                    toggler.addClass('open');
                    toggleable.trigger('toggle:open');
                }
            });
            toggleable
                .on('toggle:open', function (event) {
                event.stopPropagation();
                toggler.addClass('open');
                toggleable.addClass('open');
                if (animation in __toggleAnimations) {
                    __toggleAnimations[animation].open(toggleable);
                }
                else {
                    __toggleAnimations['show'].open(toggleable);
                }
                if ($exclude) {
                    $exclude.not(toggleable).trigger('toggle:close');
                }
                toggleable.trigger('toggle:was-opened', toggleableSelector);
            })
                .on('toggle:close', function (event) {
                event.stopPropagation();
                toggler.removeClass('open');
                toggleable.removeClass('open');
                if (animation in __toggleAnimations) {
                    __toggleAnimations[animation].close(toggleable);
                }
                else {
                    __toggleAnimations['show'].close(toggleable);
                }
                toggleable.trigger('toggle:was-closed', toggleableSelector);
            });
            toggleable.filter('.open').trigger('toggle:open');
            toggleable.not('.open').trigger('toggle:close');
        }
    });
    function initialized() {
        return $(this).hasData('toggle-initialized');
    }
}
function registerToggleAnimation(key, animation) {
    if (key in __toggleAnimations) {
        console.error(`registerToggleAnimation ERROR: toggle animation '${key}' already exists`);
    }
    else {
        __toggleAnimations[key] = animation;
    }
}
