/**
 * Returns the Provinces associated to the provided "countryId".
 * @param {number} countryId Id of the provinces' country
 * @returns {Promise<Province[]>} Promise that resolves into an array of Province objects (empty array on fail)
 */
function loadProvinces(countryId) {
    return new Promise((resolve) => {
        $.ajax({
            method: 'GET',
            url: `/restful/countries/provinces?countryId=${countryId}`,
            success: function (data) {
                resolve(data.results);
            },
            error: function () {
                resolve([]);
            }
        });
    });
}
/**
 * Updates all province inputs according to the current value of the country input in the same form. If at least one province is found, the input will become a \<select\>, it will become/remain a \<input type="text"\> otherwise.
 */
function updateProvinces() {
    $('[name*="prov"]').not('[type=hidden]').each(function () {
        const $this = $(this);
        const country = $this.closest('form').find('[name*="country"]');
        if (country.length > 0) {
            loadProvinces(country.value()).then(function (provinces) {
                if (provinces.length > 0) {
                    const id = $this.attr('id');
                    const name = $this.attr('name');
                    const type = $this.attr('type');
                    const value = $this.val();
                    const placeholder = $this.attr('placeholder');
                    const disabled = $this.is('[disabled], [readonly]') ? 'disabled' : '';
                    const readonly = $this.is('[readonly]') ? 'readonly' : '';
                    const required = $this.is('[required]') ? 'required' : '';
                    //@ts-ignore msg is guaranteed to exist
                    let options = [`<option class="hide" disabled selected>${msg['MSG_SELECT_PROVINCE']}</option>`];
                    let hasSelectedValue = false;
                    for (let i = 0; i < provinces.length; i++) {
                        const province = provinces[i];
                        const selected = (province.code == value) ? 'selected' : '';
                        options.push(`<option value="${province.code}" ${selected}>${province.name} (${province.code})</option>`);
                        hasSelectedValue = hasSelectedValue || (province.code == value);
                    }
                    if (!hasSelectedValue) {
                        options[0].replace('  ', ' selected');
                    }
                    if (readonly) {
                        $(`<input type="hidden" name="${name}" value="${value}" ${required}/>`).insertAfter($this);
                    }
                    $this.replaceWith(`
                        <select id="${id}" name="${name}" ${disabled} ${readonly} data-type="${type}" ${required} data-placeholder="${placeholder}" data-value="${value}">
                            ${options.join('\n')}
                        </select>
                    `);
                }
                else if ($this.is('select')) {
                    const id = $this.attr('id');
                    const name = $this.attr('name');
                    const type = $this.data('type');
                    const value = $this.data('value');
                    const placeholder = $this.data('placeholder');
                    const disabled = $this.is('[disabled]:not([readonly])') ? 'disabled' : '';
                    const readonly = $this.is('[readonly]') ? 'readonly' : '';
                    const required = $this.is('[required]') ? 'required' : '';
                    if (readonly) {
                        $this.siblings(`[name=${name}][type=hidden]`).remove();
                    }
                    $this.replaceWith(`<input id="${id}" type="${type}" name="${name}" ${disabled} ${readonly} ${required} value="${value}" placeholder="${placeholder}"/>`);
                }
            });
        }
    });
}
