var dropCookie = true;
var GDPRCookieDuration = 365;
var GDPRCookieName = 'complianceCookie';
var GDPRCookieValue = 'on';
/**
 * Used as the onclick function of the cookie banner close button.
 */
function cookieLawFadeOut() {
    const cookieLaw = $('#cookie-law');
    cookieLaw.remove();
    createCookie(GDPRCookieName, GDPRCookieValue, GDPRCookieDuration);
}
/**
 * Create cookie with given "name" and "value", with an expiry date "days" from the current time.
 * @param {string} name Cookie name
 * @param {string} value Cookie value
 * @param {number} days Days after which the cookie will expire, negative values make the cookie expire immediately
 */
function createCookie(name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toUTCString();
    }
    else {
        expires = '';
    }
    if (dropCookie) {
        document.cookie = name + '=' + value + expires + '; path=/';
    }
}
/**
 * Get the value of cookie "name".
 * @param name Name of the cookie to check
 * @returns {string | null} Value of the cookie, null if the cookie is not found
 */
function checkCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ')
            c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0)
            return c.substring(nameEQ.length, c.length);
    }
    return null;
}
/**
 * Deletes cookie "name".
 * @param name Name of the cookie to delete
 */
function eraseCookie(name) {
    createCookie(name, '', -1);
}
/**
 * Checks whether the GDPR cookie has been set, if not it will show the cookie banner.
 */
function checkGDPRCookie() {
    if (!checkCookie(GDPRCookieName)) {
        const cookieLaw = $('<div>').attr('id', 'cookie-law').html(cookieContent);
        cookieLaw.insertAfter('.main');
    }
}
