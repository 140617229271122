var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/**
 * Checks if "selector" exists in the current page.
 * @param {JQuerySelector} selector : Any valid JQuery selector
 * @returns {boolean} Whether "selector" exists or not
 */
function exists(selector) {
    try {
        return $(selector).length > 0;
    }
    catch (_a) {
        return false;
    }
}
/**
 * Negates "predicate"; also returns false if "predicate" is the string "false" (case insensitive).
 * @param {boolean | string | any} predicate Predicate to negate
 * @returns {boolean} Logical opposite of "predicate"
 */
function not(predicate) {
    return !predicate || (typeof predicate === 'string' && predicate.toLowerCase() === 'false');
}
/**
 * Load script from "url".
 * @param url Url of the script
 * @returns {Promise<void>} Promise that resolves if the script was downloaded successfully, rejects otherwise
 */
function loadScript(url) {
    return new Promise((resolve, reject) => {
        $.ajax({
            method: 'GET',
            url: url,
            dataType: 'script',
            async: true,
            success: () => resolve(),
            error: () => reject()
        });
    });
}
/**
 * Load recaptcha script from Google's servers.
 * @param {JQuerySelector} selector ReCaptcha selector, '.g-recaptcha' by default
 */
function loadCaptcha(selector = '.g-recaptcha') {
    if (exists(selector)) {
        const recaptcha = $(selector);
        if (!recaptcha.data('loaded')) {
            loadScript('https://www.google.com/recaptcha/api.js')
                .then(() => recaptcha.data('loaded', 'true'));
        }
    }
}
/**
 * Scrolls to the "selector" element with a smooth animation.
 * @param {JQuerySelector} selector Selector of the element ot scroll to
 * @returns {Promise<void>} Promise that resolves if the element to scroll to exists, rejects otherwise
 */
function scrollToElement(selector) {
    if (exists(selector)) {
        const offset = $(selector).offset().top;
        const headerOffset = exists('header') ? $('header').outerHeight() : 0;
        return new Promise((resolve) => {
            $('html').animate({ scrollTop: offset - headerOffset }, resolve);
        });
    }
    else {
        return Promise.reject();
    }
}
/**
 * Monitors changes in input with name="nameToMonitor" in form "form" and checks for "condition", if true "onSuccess" is run, if false "onFail" is run.
 * @param {JQuerySelector} form Form containing the input to monitor
 * @param {string} nameToMonitor Name of the input to monitor
 * @param {(input: JQuery<HTMLElement>) => boolean} condition Predicate function with the condition to check
 * @param {() => void} onSuccess Is run if "condition" is true
 * @param {() => void} onFail Is run if "condition" is false
 */
function monitorInput(form, nameToMonitor, condition, onSuccess, onFail) {
    const $form = $(form);
    const $inputToMonitor = $form.find(`[name=${nameToMonitor}]`);
    checkInput();
    $inputToMonitor.on('change', checkInput);
    function checkInput() {
        if (condition($inputToMonitor)) {
            onSuccess();
        }
        else {
            onFail();
        }
    }
}
/**
 * Disables and hides input with name="name" in form "form".
 * @param {JQuerySelector} form Form containing the input to disable
 * @param {string} name Name of the input to disable
 */
function disableInput(form, name) {
    const $form = $(form);
    const $this = $form.find(`[name=${name}]`);
    const type = $this.attr('type');
    let container = '.label-input';
    if (type == 'radio') {
        container = '.radio-container';
    }
    else if (type == 'checkbox') {
        container = '.checkbox-container';
    }
    else if (type == 'search') {
        container = '.search-container';
    }
    $this.prop('disabled', true);
    $this.closest(container).hide();
}
/**
 * Enables and unhides input with name="name" in form "form".
 * @param {JQuerySelector} form Form containing the input to enable
 * @param {string} name Name of the input to enable
 */
function enableInput(form, name) {
    const $form = $(form);
    const $this = $form.find(`[name=${name}]`);
    const type = $this.attr('type');
    let container = '.label-input';
    if (type == 'radio') {
        container = '.radio-container';
    }
    else if (type == 'checkbox') {
        container = '.checkbox-container';
    }
    else if (type == 'search') {
        container = '.search-container';
    }
    $this.prop('disabled', false);
    $this.closest(container).show();
}
/**
 * Transforms "array" of values of type T into an array of index-value pairs.
 * @param {T[]} array Array to transform
 * @returns {(readonly [number, T])[]} Array of index-value pairs
 */
function indexValuePairs(array) {
    return array.map((value, index) => [index, value]);
}
/**
 * Copies values from inputs in "sourceForm" to inputs in "destForm", according to the source name-destination name map "nameMap".
 * @param {JQuerySelector} sourceForm Form to copy from
 * @param {JQuerySelector} destForm Form to copy to
 * @param {map<string, string>} nameMap Map of inputs from "sourceForm" to copy to inputs in "destForm", in the form {"sourceName": "destName", ...}
 */
function copyFormInputs(sourceForm, destForm, nameMap) {
    const source = $(sourceForm);
    const dest = $(destForm);
    for (const [sourceName, destName] of Object.entries(nameMap)) {
        const sourceInput = source.find(`[name=${sourceName}]`);
        const destInput = dest.find(`[name=${destName}]`);
        if (exists(sourceInput) && exists(destInput)) {
            destInput.val(sourceInput.val()).trigger('change');
        }
    }
}
/**
 * Executes "fn" every "delay" ms until condition "until" is fulfilled.
 * @param {number | boolean | (() => boolean)} until Condition to be fulfilled; if the condition is a number "fn" will be executed "until" times; if it is a boolean the condition is "reversed", i.e. true will make "fn" execute forever, false wil make "fn" never execute
 * @param {number} delay Delay in ms
 * @param {() => void} fn Function to execute
 */
function strobe(until, delay, fn) {
    let condition;
    function localTimeout() {
        if (!condition()) {
            fn();
            setTimeout(localTimeout, delay);
        }
    }
    if (typeof until === 'number') {
        condition = () => {
            if (until > 0) {
                until -= 1;
                return false;
            }
            else {
                return true;
            }
        };
    }
    else if (typeof until === 'boolean') {
        condition = () => !until;
    }
    else {
        condition = until;
    }
    localTimeout();
}
/**
 * Wrapper around a call to loadScript, used to load bundled script.modules in the build folder.
 * @param module Name of the module, e.g. 'swiper' will load script.module.swiper.js
 * @param callback Function to execute on script load.
 */
function requireModule(module, callback) {
    loadScript(`${skin}/build/script.module.${module}.js`).then(callback);
}
/**
 * Initializes quantity inputs.
 */
function loadQtyInputs() {
    $('.quantity-input').not(initialized).each(function () {
        const $this = $(this);
        if ($this.val() > $this.data('max')) {
            $this.css('color', 'red');
        }
        $this.siblings('.btn-minus').on('click', function (event) {
            event.preventDefault();
            const value = getCurrentValue($this);
            checkValue(value - 1);
        });
        $this.siblings('.btn-plus').on('click', function (event) {
            event.preventDefault();
            const value = getCurrentValue($this);
            checkValue(value + 1);
        });
        $this.on('keydown input', function (event) {
            const value = getCurrentValue($this);
            const key = (event.key || '').toUpperCase();
            if (key == 'ENTER') {
                event.preventDefault();
                $this.closest('form').trigger('submit');
            }
            else if (key == 'ARROWUP') {
                checkValue(value + 1);
            }
            else if (key == 'ARROWDOWN') {
                checkValue(value - 1);
            }
            else {
                checkValue(value);
            }
        });
        function getCurrentValue($this) {
            return parseInt($this.value().toString().replace(/[^0-9]/g, '')) || 0;
        }
        function checkValue(value) {
            const min = $this.data('min') >> 0;
            const max = $this.data('max') == null ? Number.MAX_SAFE_INTEGER : $this.data('max');
            if (value > max) {
                $this.val(max);
            }
            else if (value < min) {
                $this.val(min);
            }
            else {
                $this.val(value);
            }
            $this.trigger('change');
        }
        $this.on('change', function () {
            const current = $this.val();
            const max = $this.data('max') == null ? Number.MAX_SAFE_INTEGER : $this.data('max');
            if (current <= max) {
                $this.css('color', '');
                const qtyorig = $this.data('qtyorig') >> 0;
                const otherInputsHaveChanged = $this.closest('form').find('.quantity-input').toArray().some(input => $(input).value() != $(input).data('qtyorig'));
                const event = (qtyorig != current || otherInputsHaveChanged) ? 'input:quantity-changed' : 'input:quantity-not-changed';
                $this.trigger(event);
            }
            else {
                $this.css('color', 'red');
                $this.trigger('input:quantity-not-changed');
            }
        });
        $this.data('quantity-input-initialized', true);
    });
    function initialized() {
        return $(this).hasData('quantity-input-initialized');
    }
}
/**
 * Function to bind to the event handler of an option selector.
 */
function updateCurrentModel(input, callback) {
    const $this = $(input);
    const form = $this.closest('form');
    const name = $this.attr('name');
    const models = $this.value().split(',');
    const modelId = getCurrentModelId(form);
    checkCompatibleOptions(form, name, models);
    if (modelId != null) {
        form.find('[name*=qty_model]').attr('name', `qty_model_${modelId}`);
        if (callback) {
            callback(modelId);
        }
    }
}
/**
 * Returns the current model id, according to the selected product options
 * @param form Product addtocart form
 * @returns {string} Product id
 */
function getCurrentModelId(form) {
    const selectedOptions = form.find('.options-select option:selected, .options-radio .radio:checked');
    if (exists(selectedOptions)) {
        const modelLists = selectedOptions.toArray().map(elem => $(elem).value().split(','));
        const model = modelLists.reduce((l1, l2) => l1.filter(elem => l2.includes(elem)))[0];
        return model;
    }
}
/**
 * Checks the other product options, if any, and selects the first compatible ones.
 * @param form Product addtocart form
 * @param lastInputName Name of the input that triggered the model change
 * @param models Models associated to the selected option
 */
function checkCompatibleOptions(form, lastInputName, models) {
    const otherInputs = form.find(`.options-select:not([name=${lastInputName}]), .options-radio:not(.name-${lastInputName})`);
    otherInputs.find('option, .radio')
        .filter(function () {
        const optionModels = $(this).value().split(',');
        return !optionModels.some(model => models.includes(model));
    })
        .addClass('incompatible');
    otherInputs.each(function () {
        const input = $(this);
        const incompatibleSelectedOptions = input.find('option:selected.incompatible, .radio:checked.incompatible');
        if (exists(incompatibleSelectedOptions)) {
            const firstValidOption = input.find('option:not(.incompatible), .radio:not(.incompatible)').first();
            if (firstValidOption.is('option')) {
                const val = firstValidOption.val();
                firstValidOption.closest('select').val(val);
            }
            else {
                firstValidOption.trigger('click');
            }
        }
    });
    otherInputs.find('option, .radio').removeClass('incompatible');
}
/**
 * Write "text" to the clipboard using the browser Clipboard API.
 * @param {string} text Text to write to the clipboard
 * @returns A promise resolving to true if the text is copied, false otherwise
 */
function copyTextToClipboard(text) {
    return new Promise(resolve => navigator.clipboard.writeText(text)
        .then(() => {
        resolve(true);
    })
        .catch(() => {
        resolve(false);
    }));
}
/**
 * Replaces elements in the page corresponding to "selectors" with the ones retrieved from the AJAX call to "url" in "settings" (the current URL by default).
 * @param {string[]} selectors List of JQuery selectors corresponding to the elements to replace
 * @param {(replacedAmounts: map<string, number>) => void} callback Callback function to call after replacing the page elements
 * @param {string} settings Settings for the JQuery AJAX call
 */
function replacePageElements(selectors, callback = () => { }, settings = {}) {
    const { url } = settings, otherParams = __rest(settings, ["url"]);
    $.ajax(Object.assign({ method: 'GET', url: url || window.location.href, dataType: 'html', success: function (data) {
            const response = $('<div>').append(data);
            const info = {
                amounts: selectors.reduce((a, b) => { a[b] = 0; return a; }, {}),
                replacements: selectors.reduce((a, b) => { a[b] = []; return a; }, {}),
                response: response.clone()
            };
            for (let selector of selectors) {
                const element = response.find(selector);
                if (exists(element)) {
                    $(selector).replaceWith(element);
                    info.replacements[selector].push(element);
                    info.amounts[selector] += 1;
                }
            }
            callback(info);
        } }, otherParams));
}
/**
 * Returns true if the current browser is Internet Explorer (according to its User Agent), false otherwise.
 * @returns A boolean value
 */
function isIE() {
    const ua = window.navigator.userAgent;
    return /MSIE|Trident/.test(ua);
}
/**
 * Creates an array containing all the numbers between "start" and "end" (inclusive), optionally with a "step" interval.
 * @param start Start of the range
 * @param end End of the range
 * @param step Interval between elements
 * @returns Array of numbers
 */
function range(start, end, step = 1) {
    let ret = Array(Math.ceil((end - start + 1) / step));
    for (let i = 0; i < ret.length; i++) {
        ret[i] = start + i * step;
    }
    return ret;
}
/**
 * Wrapper function to prevent repeated calls to GTM callback functions
 * @param callback Callback function to wrap
 * @returns Wrapped callback function
 */
function GTMCallback(callback) {
    return (containerId) => {
        /**
         * If trackers are blocked containerId === undefined
         * Google Tag Manager container id starts with "GTM-"
         */
        if ((!containerId || containerId.startsWith("GTM-")) && typeof callback === "function") {
            callback();
        }
    };
}
function setupVideoEmbeds(selector = '.video-container') {
    const videos = (typeof selector === 'string' ? $(selector).toArray() : selector instanceof Array ? selector : [selector]);
    videos.forEach(video => {
        if (!video.classList.contains('loaded')) {
            const src = video.dataset.src;
            const script = video.dataset.script;
            if (src.length > 0) {
                if (script.length > 0) {
                    const init = () => {
                        $.getScript(script);
                        $(video).find('iframe')[0].src = src;
                        video.classList.add('loaded');
                        $(video).off('click', init);
                    };
                    $(video).on('click', init);
                }
                else {
                    const init = () => {
                        $(video).find('iframe')[0].src = src;
                        video.classList.add('loaded');
                        $(video).off('click', init);
                    };
                    $(video).on('click', init);
                }
            }
        }
    });
}
