var subscribeFormValidator;
function submitSubscribeForm() {
    const $form = $('#subscribe-form');
    if (!subscribeFormValidator.checkForm() && !subscribeFormValidator.valid()) {
        subscribeFormValidator.showErrors(undefined);
        return;
    }
    if (checkCountryRedirect()) {
        return;
    }
    $.ajax({
        type: $form.attr('method'),
        url: $form.attr('action'),
        data: $form.serialize(),
        dataType: 'json',
        beforeSend: function () {
            $form.find(':input').prop('disabled', true);
        },
        success: function (data) {
            if (data.success) {
                if (window.dataLayer) {
                    pushGTMEventWithCallback(300, {
                        event: 'sign_up',
                        eventCallback: GTMCallback(function () {
                            window.location.href = $form.data('redirectsuccess');
                        })
                    });
                }
                else {
                    window.location.href = $form.data('redirectsuccess');
                }
            }
            else {
                if (data.errors.message in msg) {
                    showMessageBox('danger', msg[data.errors.message]);
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
                if (data.errors.message == 'ERR_USEREXISTS') {
                    const username = $form.find('[name=username]');
                    const usernameStep = username.closest('.step').data('step');
                    $('#subscribe-multistep').trigger('multi-step:go-to', usernameStep);
                    username
                        .addClass('error')
                        .after(`<label class="error" for="username">${msg['ERR_USEREXISTS']}</label>`);
                }
            }
        },
        error: function () {
            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
        },
        complete: function () {
            $form.find(':input').prop('disabled', false);
        }
    });
}
function checkCountryRedirect() {
    const $form = $('#subscribe-form');
    const currentCountry = $form.find('[name*=country] option:selected');
    const redirects = JSON.parse($form.find('[name=redirects]').value(val => val.toString()));
    for (const condition in redirects) {
        if (currentCountry.is(condition)) {
            const dialog = $('#redirect-dialog');
            dialog.find('.text').html(redirects[condition]);
            //@ts-ignore
            dialog.get(0).showModal();
            return true;
        }
    }
    return false;
}
function subscribeComponent() {
    loadCaptcha();
    subscribeFormValidator = $('#subscribe-form').validate();
    $('#subscribe-form [name*=country]').on('change', checkCountryRedirect);
    $('#subscribe-multistep .btn-next').on('multi-step:next', function (event) {
        if (checkCountryRedirect()) {
            event.preventDefault();
            event.stopPropagation();
        }
    });
    $('#subscribe-form .radio').on('change', function () {
        $('#subscribe-form .comments').slideDown();
    });
    toggleInterests();
    $('#subscribe-form #newsletter').on('change', toggleInterests);
    function toggleInterests() {
        if ($('#subscribe-form #newsletter').is(':checked')) {
            $('#show-newsletter').slideDown()
                .find('input').prop('disabled', false);
        }
        else {
            $('#show-newsletter').slideUp()
                .find('input').prop('disabled', true);
        }
    }
    $('#subscribe-multistep .steps').on('multi-step:next', function (event) {
        const activeStepInputs = $(this).find('.step.active').find('input, select');
        if (!activeStepInputs.valid()) {
            event.stopPropagation();
        }
    });
    $('#subscribe-form [name="i_country_id"]').on('change', function () {
        var _a, _b;
        const cfpiva = this.closest('form').querySelector('[name*="cfpiva"]');
        const eu = (_b = (_a = this.querySelector('option:checked')) === null || _a === void 0 ? void 0 : _a.dataset) === null || _b === void 0 ? void 0 : _b.eu;
        if (!!eu && eu != 'EXTRA-EU') {
            cfpiva.closest('.label-input').classList.remove('hide');
            cfpiva.disabled = false;
            cfpiva.required = true;
        }
        else {
            cfpiva.closest('.label-input').classList.add('hide');
            cfpiva.disabled = true;
            cfpiva.required = false;
        }
    });
    $('[name*="country"]').not('[type=hidden]').on('change', updateProvinces);
    updateProvinces();
}
