;
var swipers = {};
var defaultSwiperSettings = {
    slidesPerView: 1,
    centerInsufficientSlides: true,
    spaceBetween: 20,
    autoplay: {
        delay: 2000,
    },
    speed: 1500,
    breakpoints: {
        641: {
            slidesPerView: 2
        },
        1025: {
            slidesPerView: 4
        }
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
};
var noSwiperNavigation = {
    navigation: {},
};
var swiperLoop = {
    loop: true,
};
var addSwiperEntry;
function swiperComponent() {
    requireModule('swiper', function () {
        addSwiperEntry = (key, settings, eventHandlers = {}) => {
            if (exists(key) && not(swipers[key])) {
                swipers[key] = new Swiper(key, settings);
                for (let [event, handler] of Object.entries(eventHandlers)) {
                    swipers[key].on(event, handler);
                }
            }
        };
        // BEGIN: Custom swipers
        addSwiperEntry('#brands-swiper', Object.assign(Object.assign(Object.assign({}, defaultSwiperSettings), noSwiperNavigation), {
            slidesPerView: 2.5,
            spaceBetween: 8,
            loop: true,
            speed: 2500,
            breakpoints: {
                641: {
                    spaceBetween: 16,
                    slidesPerView: 3.5
                },
                1025: {
                    spaceBetween: 24,
                    slidesPerView: 5
                }
            },
        }));
        addSwiperEntry('#catalog-swiper', Object.assign(Object.assign(Object.assign({}, defaultSwiperSettings), noSwiperNavigation), {
            slidesPerView: 1.5,
            spaceBetween: 16,
            loop: false,
            breakpoints: {
                641: {
                    spaceBetween: 16,
                    slidesPerView: 2.5
                },
                1025: {
                    spaceBetween: 24,
                    slidesPerView: 3.5
                },
                1440: {
                    spaceBetween: 24,
                    slidesPerView: 5
                },
            }
        }));
        addSwiperEntry('#static-product-swiper', Object.assign(Object.assign(Object.assign({}, defaultSwiperSettings), noSwiperNavigation), {
            slidesPerView: 1.5,
            spaceBetween: 16,
            loop: false,
            breakpoints: {
                641: {
                    spaceBetween: 16,
                    slidesPerView: 2.5
                },
                1025: {
                    spaceBetween: 24,
                    slidesPerView: 4
                },
            }
        }));
        for (const swiper of $('[id*=category-swiper]').toArray()) {
            addSwiperEntry('#' + swiper.id, Object.assign(Object.assign({}, defaultSwiperSettings), noSwiperNavigation));
        }
        addSwiperEntry('#best-sellers-swiper', Object.assign(Object.assign(Object.assign({}, defaultSwiperSettings), noSwiperNavigation), {
            slidesPerView: 1.5,
            spaceBetween: 16,
            loop: false,
            breakpoints: {
                641: {
                    spaceBetween: 16,
                    slidesPerView: 2
                },
                1025: {
                    spaceBetween: 24,
                    slidesPerView: 4
                },
            }
        }));
        addSwiperEntry('#most-searched-swiper', Object.assign(Object.assign({}, defaultSwiperSettings), noSwiperNavigation));
        addSwiperEntry('#brand-image-swiper', Object.assign(Object.assign({}, defaultSwiperSettings), noSwiperNavigation));
        addSwiperEntry('#product-images-swiper', Object.assign(Object.assign(Object.assign({}, defaultSwiperSettings), noSwiperNavigation), swiperLoop));
        addSwiperEntry('#same-brand-products-swiper', Object.assign(Object.assign(Object.assign({}, defaultSwiperSettings), noSwiperNavigation), swiperLoop));
        addSwiperEntry('#same-category-products-swiper', Object.assign(Object.assign(Object.assign({}, defaultSwiperSettings), noSwiperNavigation), swiperLoop));
        // END: Custom swipers
        const uninitializedSwipers = $('.swiper-container').not('.swiper-container-initialized').toArray();
        for (let [index, swiper] of indexValuePairs(uninitializedSwipers)) {
            const id = $(swiper).attr('id');
            if (not(id)) {
                swiper.id = `generic-swiper-${index}`;
                addSwiperEntry(`#${swiper.id}`, defaultSwiperSettings);
            }
            else if (not(swipers[id])) {
                addSwiperEntry(`#${id}`, defaultSwiperSettings);
            }
        }
    });
}
