/**
 * Initialized intl-tel-input elements in the page if any applicable input is found (name includes 'tel' or 'cel').
 */
function initTelDropdowns() {
    if (exists('input[name*="cel"]:not([type="hidden"])')) {
        requireModule('intl-tel-input', function () {
            for (const type of ['cel', 'tel']) {
                const prefixInput = $(`input[name*="${type}"][type="hidden"]`);
                const input = $(`input[name*="${type}"]`).not('[type="hidden"]');
                if (exists(input)) {
                    input.val(prefixInput.value() + input.value());
                    const iti = intlTelInput(input[0], {
                        utilsScript: `/skins/vendor/intl-tel-input-utils.js`,
                        separateDialCode: true
                    });
                    input.on('countrychange', function () {
                        prefixInput.val(iti.getSelectedCountryData().dialCode);
                    });
                }
            }
        });
    }
}
