;
$.fn.extend({
    value: function (caster) {
        if (caster) {
            return caster($(this).first().val().toString());
        }
        else {
            return $(this).first().val();
        }
    },
    dataAs: function (data, caster) {
        if (caster) {
            const dataValue = $(this).first().data(data);
            return caster(dataValue == null ? '' : dataValue);
        }
        else {
            return $(this).first().data(data);
        }
    },
    disabled: function (setting) {
        return $(this).prop('disabled', setting);
    },
    required: function (setting) {
        return $(this).prop('required', setting);
    },
    label: function (content) {
        const label = $(this).siblings('label').first();
        if (content) {
            label.text(content);
            return $(this);
        }
        else {
            return label.text().trim();
        }
    },
    hasData: function (data, value) {
        const $this = $(this);
        if (Array.isArray(data)) {
            for (const datum of data) {
                if ($this.data(datum) === undefined) {
                    return false;
                }
            }
            return true;
        }
        else if (value) {
            return $this.data(data) == value;
        }
        else {
            return $this.data(data) !== undefined;
        }
    },
    withData: function (data, value_or_condition) {
        return $(this).filter(function () {
            if (!Array.isArray(data) && value_or_condition) {
                if (value_or_condition instanceof Function) {
                    return value_or_condition($(this).data(data));
                }
                else {
                    return $(this).hasData(data, value_or_condition);
                }
            }
            else {
                return $(this).hasData(data);
            }
        });
    },
    hasEventHandler: function (event) {
        //@ts-ignore $._data is a private, but existing method
        const events = ($._data($(this).get(0), 'events') || {});
        return event in events;
    },
    onLast: function (events, delay, handler) {
        return $(this).on(events, function () {
            const $this = $(this);
            const timestamp = Date.now();
            $this.data('timestamp', timestamp);
            setTimeout(function () {
                if ($this.data('timestamp') == timestamp) {
                    handler($this);
                }
            }, delay);
        });
    }
});
