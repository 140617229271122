function faqPage() {
    $('.faq-section').each(function (idx) {
        const section = $(this);
        const sectionId = `faq-section-${idx}`;
        section
            .attr('id', sectionId)
            .find('.faq .toggle').each(function (idx) {
            const toggle = $(this);
            const answer = toggle.siblings('.answer');
            const answerId = `${sectionId}-answer-${idx}`;
            answer.attr('id', answerId);
            toggle
                .attr('data-toggle', `#${answerId}`)
                .attr('data-toggle-slide', '')
                .attr('data-toggle-exclude', `#${sectionId} .answer`);
        });
    });
}
function openFaqAnswer(answerId) {
    const answer = $(answerId);
    if (exists(answer)) {
        const toggle = answer.siblings('.toggle');
        answer.trigger('toggle:open');
        window.scrollToElement(toggle);
    }
}
