/**
 * Initializes tabs within "scope". Use guide:
 *
 * Terminology:
 *      tabs container: element with the data-tabs-container attribute
 *      tabs scope: element indicated by the parameter of data-tabs-container, scope within which the tabs will work, several tab containers can point to the same tabs scope, tabs within the same tabs scope are mutually exclusive
 *      tab selector: element(s) with the data-tab attribute within a tabs container, associated to the tab indicated by the parameter of data-tab, several tab selectors can be associated to the same tab
 *      tab: element indicated by the parameter of data-tab
 *
 * Tabs container attributes:
 *      data-tabs-container="selector": Indicates that this element will be a tabs container and that the tabs scope will be the element matching "selector"
 *
 * Tab selector attributes:
 *      data-tab="selector": Indicates that this element will be a tab selector and that the element(s) matching "selector" will be its associated tab(s)
 *
 * Tab selector classes:
 *      .selected: when its associated tab is selected
 *
 * Tab classes:
 *      .selected: when the tab is selected
 *
 * Tabs scope events:
 *      "tabs:change"(tab): selects the tab indicated by "tab"
 *
 * Tabs container events:
 *      "tabs:update-tab"(tab): selects the tab selector associated to "tab"
 *
 * Other events:
 *      "tabs:was-changed"([container, tab]): triggered by the tabs container when a tab changes, "container" indicates the tabs container, "tab" indicates the selected tab
 *
 * @param scope Selector of the element within which activate tabs
 */
function initTabs(scope = document) {
    $(scope).find('*').withData('tabs-container').not(initialized).each(function () {
        const tabsContainer = $(this);
        const tabsScopeSelector = tabsContainer.data('tabs-container');
        tabsContainer.data('tabs-initialized', true);
        if (exists(tabsScopeSelector)) {
            const tabsScope = $(tabsScopeSelector);
            const tabSelectors = tabsContainer.find('*').withData('tab').toArray()
                .map(elem => $(elem).data('tab'))
                .filter(tab => exists(`${tabsScopeSelector} ${tab}`));
            if (tabSelectors.length > 0) {
                let currentTabs = (tabsScope.data('tabs') || []);
                currentTabs = currentTabs.concat(tabSelectors);
                currentTabs = Array.from(new Set(currentTabs));
                tabsScope.data('tabs', currentTabs);
            }
            tabSelectors.forEach(function (selector) {
                const tabSelector = tabsContainer.find('*').withData('tab', selector);
                tabSelector.on('click', function () {
                    tabsScope.trigger('tabs:change', selector);
                });
            });
            tabsContainer.on('tabs:update-tab', function (event, selector) {
                event.stopPropagation();
                tabsContainer.find('*').withData('tab')
                    .removeClass('selected')
                    .withData('tab', selector)
                    .addClass('selected');
            });
            tabsScope.on('tabs:change', function (event, selector) {
                event.stopPropagation();
                const tabs = tabsScope.data('tabs') || [];
                if (tabs.includes(selector)) {
                    tabs.filter((tab) => tab != selector).forEach(function (tab) {
                        tabsScope.find(tab).hide();
                    });
                    tabsScope.find(selector).show();
                }
                tabsContainer.trigger('tabs:update-tab', selector);
                tabsScope.trigger('tabs:was-changed', [tabsScope, selector]);
            });
            let currentTab = tabsScope.find('.selected').withData('tab');
            if (!exists(currentTab)) {
                currentTab = tabsContainer.find('*').withData('tab').first();
                currentTab.addClass('selected');
            }
            tabsScope.trigger('tabs:change', currentTab.data('tab'));
        }
    });
    function initialized() {
        return $(this).hasData('tabs-initialized');
    }
}
